<template>
<v-app>
    <v-main app>
        <v-img src="https://firebasestorage.googleapis.com/v0/b/breakstamp.appspot.com/o/landing%2FLanding%20Page.jpg?alt=media&token=ceea2e2e-9415-41c9-84d0-d194143a0435"></v-img>
    </v-main>
    <v-footer app class="ma-0 pa-0">
        <div
            elevation="0"
            :ripple="false"
            class="
              font-weight-bold
              py-5
              text-sm
              d-flex
              justify-center
            "
            style="color:white;width:100%;background-color:#EE7E71;cursor:pointer"
            @click="goLink">
            도장깨기 앱 다운로드
        </div>
    </v-footer>
</v-app>
</template>

<script>
export default {
    name: 'App',

    components: {},

    data: () => ({
        //
    }),

    methods: {
      goLink() {
        console.log('goLink');
        location.href='https://breakstamp.page.link/Eit5';
      }
    }
};
</script>
